import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';
import SEO from '../components/SEO';
import Link from '../components/Link';
import EmptyState from '../components/EmptyState';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailWrongLink = () => {
  useBrowserLocale();

  return (
    <>
      <SEO title="Account restoration error" />
      <Container>
        <EmptyState
          title="Error, account restoration was not successful (no username). Please try pasting the link from the email manually into your browser’s address bar."
          action={
            <ButtonLink to="https://cara.app.link/Dk8UOqildy">
              <FormattedMessage
                id="pages.email.continueToApp"
                defaultMessage="Continue to App"
              />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(EmailWrongLink, { isNewLayout: true });
